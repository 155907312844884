








































































































import { Component, Vue } from 'vue-property-decorator';
import ContractSavingsCredentialsKuspitViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-credentials-kuspit-view-model';

@Component({ name: 'ContractSavingsCredentialsKuspit' })
export default class ContractSavingsCredentialsKuspit extends Vue {
  contract_savings_credentials_kuspit_view_model = Vue.observable(
    new ContractSavingsCredentialsKuspitViewModel(),
  );

  async sendCredentials() {
    const send_credentials = await this.contract_savings_credentials_kuspit_view_model
      .sendCredentials();
    if (send_credentials) {
      this.$emit('endProcess');
    }
  }

  created() {
    this.$emit('loadingInfo', this.contract_savings_credentials_kuspit_view_model.is_loading);
  }
}
