import TYPES from '@/types';

// Application
import {
  GetCustomerKuspitCredentialsQuery,
} from '@/modules/my-investment/kuspit/customer-kuspit-credentials/application/queries';

import {
  SendCustomerKuspitCredentialsCommand,
} from '@/modules/my-investment/kuspit/customer-kuspit-credentials/application/commands';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ContractSavingsCredentialsKuspitViewModel {
  @Inject(TYPES.GET_CUSTOMER_KUSPIT_CREDENTIALS_QUERY)
  private readonly getCustomerKuspitCredentialsQuery!: GetCustomerKuspitCredentialsQuery

  @Inject(TYPES.SEND_CUSTOMER_KUSPIT_CREDENTIALS_COMMAND)
  private readonly sendCustomerKuspitCredentialsCommand!: SendCustomerKuspitCredentialsCommand

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  i18n_namespace = 'components.contract-savings.credentials.kuspit';

  small_screen: null | boolean = null;

  show_password = false;

  is_loading = false;

  get credentials() {
    return this.getCustomerKuspitCredentialsQuery.execute();
  }

  async sendCredentials() {
    this.is_loading = true;
    try {
      await this.sendCustomerKuspitCredentialsCommand.execute();
    } catch {
      this.messageNotifier.showErrorNotification('Ha ocurrido un error al crear la cuenta');
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  goToDashboard = () => {
    window.location.reload();
  }
}
