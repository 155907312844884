var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"src":require('@/assets/icons/ahorro_kuspit.svg'),"max-width":"64px"}})],1),_c('h3',{staticClass:"text-center primary--text ma-5"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_credentials_kuspit_view_model.i18n_namespace) + ".title")))+" ")]),_c('p',{class:{
        'text-font-secondary primary--text': true,
        'text-center': !_vm.contract_savings_credentials_kuspit_view_model.small_screen
      }},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_credentials_kuspit_view_model.i18n_namespace) + ".text")))+" ")]),_c('p',{class:{
        'text-font-secondary sky-blue--text mt-0 pt-0 text-center': true,
        'text-center': !_vm.contract_savings_credentials_kuspit_view_model.small_screen
      }},[_c('a',{staticClass:"sky-blue--text",attrs:{"href":"https://www.kuspit.com/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_credentials_kuspit_view_model.i18n_namespace) + ".link")))+" ")])]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('label',{staticClass:"inactive-blue--text"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_credentials_kuspit_view_model.i18n_namespace) + ".email")))+" ")]),_c('v-text-field',{staticClass:"v-text-field--blue pt-0",attrs:{"name":"rfc","color":"light-blue","readonly":"","prepend-inner-icon":"mdi-email","type":"email","value":_vm.contract_savings_credentials_kuspit_view_model.credentials.username}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('label',{staticClass:"inactive-blue--text"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_credentials_kuspit_view_model.i18n_namespace) + ".password")))+" ")]),_c('v-text-field',{staticClass:"v-text-field--blue pt-0",attrs:{"name":"password","color":"light-blue","prepend-inner-icon":"mdi-lock","autocomplete":"off","readonly":"","append-icon":_vm.contract_savings_credentials_kuspit_view_model.show_password ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.contract_savings_credentials_kuspit_view_model.show_password ? 'text' : 'password',"value":_vm.contract_savings_credentials_kuspit_view_model.credentials.password},on:{"click:append":function($event){_vm.contract_savings_credentials_kuspit_view_model.show_password = !
        _vm.contract_savings_credentials_kuspit_view_model.show_password}}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{attrs:{"rounded":"","color":"accent","large":"","width":"100%","height":"3.5em","disabled":_vm.contract_savings_credentials_kuspit_view_model.is_loading},on:{"click":_vm.sendCredentials}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_credentials_kuspit_view_model.i18n_namespace) + ".send_by_email")))+" ")])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{attrs:{"rounded":"","color":"secondary","large":"","width":"100%","height":"3.5em"},on:{"click":_vm.contract_savings_credentials_kuspit_view_model.goToDashboard}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.contract_savings_credentials_kuspit_view_model.i18n_namespace) + ".go_to_dashboard")))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }